<template>
  <section>
    <p class="text-h6 secondary--text">Catálogo de productos</p>
    <v-row class="filters-row">
      <v-col cols="8" sm="6" md="4" lg="4">
        <v-select
          v-model="filters.id_estado"
          :items="estados_list"
          item-text="nombre"
          item-value="id"
          label="Estados"
          @change="setFilters()"
          outlined
          clearable
        >
        </v-select>
      </v-col>
      <v-col cols="8" sm="6" md="4" lg="4">
        <v-select
          v-model="filters.id_fase"
          :items="phase_list"
          item-text="nombre"
          item-value="id"
          label="Etapas"
          @change="setFilters()"
          outlined
          clearable
        >
        </v-select>
      </v-col>
      <v-col cols="8" sm="3" md="4" lg="4">
        <div class="d-flex justify-end">
          <v-btn class="ma-2" color="secondary" dark @click="AgregarConvenio()">
            Agregar convenio
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <data-table-component
          v-models:select="porPaginaModel"
          v-models:pagina="paginaModel"
          :headers="headers"
          :items="Listado_convenios"
          @paginar="paginar"
          :total_registros="filters.total_rows"
        >
          <template v-slot:[`item.created_at`]="{ item }">
            {{ moment(item.created_at).format("DD/MM/YYYY") }}
          </template>

          <template v-slot:[`item.nombre_estado`]="{ item }">
            <v-chip
              v-if="item.activo"
              label
              :color="getColor(item.nombre_estado)"
              :text-color="item.id_estado === 6 ? 'white' : 'black'"
            >
              {{ item.nombre_estado }}
            </v-chip>
            <v-chip
              v-if="!item.activo"
              label
              color="#E0E5F1"
              text-color="black"
            >
            Deshabilitado
            </v-chip>
          </template>

          <template v-slot:[`item.phase`]="{ item }">
            <template v-if="item.fase.length && item.id_estado !== 3">
              <div v-for="i in item.fase" :key="i.id">
                {{ i.nombre }}
              </div>
            </template>
            <template v-else> {{ getCustomMessage(item.id_estado) }} </template>
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <div class="d-flex justify-center">
              <v-switch
                class="table-input"
                @change="setAgreementToActive(item)"
                v-model="item.activo"
                color="green"
              >
              </v-switch>
            </div>
          </template>

          <template v-slot:[`item.acciones`]="{ item }">
            <v-icon
              v-if="handleVisibility(item)"
              large
              color="primary"
              @click="verOferta(item)"
            >
              mdi-eye
            </v-icon>
            <v-icon
              v-if="
                item.id_estado === 2 ||
                item.id_estado === 3 ||
                item.id_estado === 4 ||
                !item.activo
              "
              :disabled="item.activo === false"
              large
              color="primary"
              @click="configurarAgreementMarco(item)"
            >
              mdi-cog-outline
            </v-icon>
          </template>
        </data-table-component>
      </v-col>
    </v-row>

    <!-- Modal activar/desactivar un convenio -->
    <v-dialog v-model="showActiveDialog" width="600" close persistent>
      <v-card class="pa-2">
        <v-card-title
          class="text-h6 primary--text justify-center font-weight-bold"
        >
          ¿Deseas
          {{ !agreementSelected.activo ? "desactivar" : "activar" }} el convenio
          marco?
        </v-card-title>

        <br />
        <v-card-text
          class="primary--text d-flex flex-column justify-center text-center"
        >
          De confirmar la siguiente acción los cambios no serán reversibles. El
          registro correspondiente quedará
          {{ !agreementSelected.activo ? "inactivo" : "activo" }}
        </v-card-text>
        <br />
        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="ma-1"
            outlined
            @click="(showActiveDialog = false), resetSwitch()"
          >
            Cancelar
          </v-btn>
          <v-btn
            class="ma-1"
            color="secondary"
            dark
            @click="changeStatusAgreement()"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import AppPaginationComponent from "../../components/AppPaginationComponent.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import moment from "moment-timezone";
import DataTableComponent from "../../components/DataTableComponent.vue";

export default {
  components: { AppPaginationComponent, DataTableComponent },
  name: "convenioMarcoView",

  data: () => ({
    switch1: true,
    agreementSelected: {},
    showActiveDialog: false,
    reset: false,
    headers: [
      {
        text: "Correlativo",
        align: "start",
        sortable: true,
        value: "correlativo",
      },
      {
        text: "Fecha de creación",
        align: "start",
        sortable: true,
        value: "created_at",
      },
      { text: "Nombre", align: "start", sortable: true, value: "nombre" },
      {
        text: "Estado",
        align: "start",
        sortable: false,
        value: "nombre_estado",
        width: 150,
      },
      { text: "Etapa", align: "start", sortable: false, value: "phase" },
      { text: "Activo", align: "start", sortable: false, value: "active" },
      { text: "Acciones", align: "center", sortable: false, value: "acciones" },
    ],
  }),
  computed: {
    ...mapState("convenioMarcoAdmin", [
      "Listado_convenios",
      "phase_list",
      "filters",
      "estados_list",
      "tab",
    ]),
    porPaginaModel: {
      get() {
        return this.filters.per_page;
      },
      set(value) {
        this.setFiltroPorPagina(value);
      },
    },
    paginaModel: {
      get() {
        return this.filters.page;
      },
      set(value) {
        this.setFiltroPage(value);
      },
    },
  },
  methods: {
    ...mapActions("convenioMarcoAdmin", [
      "saveIdAgreementMarco",
      "getConveniosList",
      "getFasesList",
      "getEstadosList",
    ]),
    ...mapMutations("convenioMarcoAdmin", [
      "setIsEdit",
      "setIdEstadoConvnio",
      "setStartDate",
      "setAgreementDetail",
      "setAgreementState",
      "setReadOnlyOffers",
      "setTab",
      "setObsAgreementList",
      "setDocumentAgreementList",
      "setAlertCatalogoObs",
      "setAlertDocumentos",
      "setAlertEtapas",
    ]),
    handleVisibility(item) {
      if (item.fase?.length > 0 && item.id_estado !== 3) {
        return (
          item.fase[0]?.id_fase === 2 ||
          item.fase[0]?.id_fase === 3 ||
          item.fase[0]?.id_fase === 4 ||
          item.fase[0]?.id_fase === 5 
        );
      } else {
        return (item.id_estado === 1 || item.id_estado === 5) && item.activo;
      }
    },
    getCustomMessage(id_estado) {
      switch (id_estado) {
        case 5:
          return "En ejecución";
        case 1:
          return "En ejecución";
        case 6:
          return "";
        default:
          return "Convenio sin etapa vigente";
      }
    },
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.filters.page = pagina;
      this.filters.per_page = cantidad_por_pagina;
      this.getConveniosList();
    },
    getColor(status_name) {
      // if (active == false) return 'grey'
      // else return 'green'
      switch (status_name) {
        case "Vigente":
          return "vigente";
          break;
        case "Deshabilitado":
          return "deshabilitado";
          break;
        case "Configurando":
          return "pendiente";
          break;
        case "Publicado":
          return "publicado";
          break;
        case "Cerrado":
          return "cerrado";
          break;
        case "Finalizado":
          return "blueDarkMinsal";
          break;
      }
    },
    AgregarConvenio() {
      this.setIsEdit(false);
      this.saveIdAgreementMarco(null);
      this.setDocumentAgreementList([]);
      this.setObsAgreementList([]);
      this.setTab(0);
      this.setAlertCatalogoObs(false);
      this.setAlertDocumentos(false);
      this.setAlertEtapas(false);
      this.$router.push("/crear-convenio-marco");
    },
    paginate(params) {
      this.filters.page = params.pagina;
      this.filters.per_page = params.cantidad_por_pagina;
      this.getConveniosList().then(() => (this.reset = false));
    },
    configurarAgreementMarco(item) {
      this.setIsEdit(true);
      this.saveIdAgreementMarco(item.id);
      this.setIdEstadoConvnio(item.id_estado);
      this.setTab(0);
      this.setStartDate(item.start_date);
      this.setAgreementState(item.id_status);
      this.setAlertCatalogoObs(false);
      this.setAlertDocumentos(false);
      this.setAlertEtapas(false);
      this.$router.push("/crear-convenio-marco");
    },
    setFilters() {
      this.filters.page = 1;
      this.reset = true;
      this.getConveniosList().then(() => (this.reset = false));
    },
    verOferta(item) {
      if (item.fase?.length > 0) {
        item.fase.forEach((element) => {
          if (element.nombre === "Evaluación de ofertas") {
            this.setReadOnlyOffers(false);
            this.setAgreementDetail(item);
            this.$router.push("/evaluacion-ofertas");
          } else if (element.nombre === "Recepción de ofertas") {
            this.setReadOnlyOffers(false);
            this.setAgreementDetail(item);
            this.$router.push("/recepcion-ofertas");
          } else if (
            element.nombre === "Cierre" ||
            element.nombre === "Resultados"
          ) {
            this.setReadOnlyOffers(true);
            this.setAgreementDetail(item);
            this.$router.push("/evaluacion-ofertas");
          }
        });
      } else {
        this.setReadOnlyOffers(true);
        this.setAgreementDetail(item);
        this.$router.push("/evaluacion-ofertas");
      }
    },
    setAgreementToActive(item) {
      this.agreementSelected = item;
      this.showActiveDialog = true;
    },
    resetSwitch() {
      let index = this.Listado_convenios.indexOf(this.agreementSelected);
      this.Listado_convenios[index].activo = !this.agreementSelected.activo;
      this.$forceUpdate();
    },
    async changeStatusAgreement() {
      let response = await this.services.AgreementMarco.activeStatusAgreement(
        this.agreementSelected.id
      );
      if (response?.status == 200 || response?.status == 204) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Convenio actualizado correctamente",
        });
        this.getConveniosList().then(() => (this.reset = false));
        this.agreementSelected = {};
        this.showActiveDialog = false;
      }
    },
  },
  async created() {
    this.getConveniosList();
    this.getFasesList();
    this.getEstadosList();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name !== "crear-convenio-marco") {
        vm.filters.page = 1;
        vm.filters.per_page = 10;
        vm.filters.id_fase = null;
        vm.filters.id_estado = null;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
:deep(.v-chip) {
  width: -webkit-fill-available !important;
  display: flex !important;
  justify-content: center !important;
}
</style>
