<template>
  <section>
    <v-row>
      <v-col cols="12" md="10">
        <p class="text-h6 primary--text">Configuración de Convenio Marco</p>
        <v-tabs v-model="tabIndex" background-color="transparent">
          <v-tab>
            <v-icon class="mx-2">mdi-clipboard-text-outline</v-icon>General
          </v-tab>
          <v-tab
            :disabled="id_agreement_marco === null"
            @click="changeColor(1)"
          >
            <v-icon
              class="mx-2"
              :color="
                bad_catalogo_obs && lista_obs_convenio.length === 0
                  ? color_obs
                  : ''
              "
            >
              mdi-format-list-bulleted
            </v-icon>
            <span
              :class="
                bad_catalogo_obs && lista_obs_convenio.length === 0
                  ? `${color_obs}--text`
                  : ''
              "
            >
              Catálogo OBS
            </span>
          </v-tab>
          <v-tab
            :disabled="id_agreement_marco === null"
            @click="changeColor(2)"
          >
            <v-icon
              class="mx-2"
              :color="
                bad_documentos && lista_document_convenio.length === 0
                  ? color_doc
                  : ''
              "
            >
              mdi-book-open-page-variant-outline
            </v-icon>
            <span
              :class="
                bad_documentos && lista_document_convenio.length === 0
                  ? `${color_doc}--text`
                  : ''
              "
            >
              Documentos del proceso
            </span>
          </v-tab>
          <v-tab
            :disabled="id_agreement_marco === null"
            @click="changeColor(3)"
          >
            <v-icon
              class="mx-2"
              :color="bad_etapas && !isConfigurated() ? color_etapas : ''"
              >mdi-format-list-numbered</v-icon
            >
            <span
              :class="
                bad_etapas && !isConfigurated() ? `${color_etapas}--text` : ''
              "
              >Etapas del proceso</span
            >
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabIndex">
          <v-tab-item>
            <general-component
              @enablePublicar="(disabled = false), getPhasesAgreement()"
            />
          </v-tab-item>
          <v-tab-item>
            <catalogo-obs-component />
          </v-tab-item>
          <v-tab-item>
            <documentos-proceso-component />
          </v-tab-item>
          <v-tab-item>
            <etapas-proceso
              @reload="getPhasesAgreement"
              :headers="headers"
              :items="lista_fases_convenio"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      <v-col cols="12" md="2" class="d-flex">
        <v-btn
          v-if="agreement?.fecha_publicacion == null && tabIndex == 3"
          :loading="loading"
          x-large
          color="secondary"
          :disabled="disabled || vigencia_saved"
          @click="publicar()"
        >
          Publicar
        </v-btn>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import GeneralComponent from "./components/GeneralComponent.vue";
import CatalogoObsComponent from "./components/CatalogoOBSComponent.vue";
import DocumentosProcesoComponent from "./components/DocumentosProcesoComponent.vue";
import EtapasProceso from "../../components/EtapasProcesoComponent.vue";
import moment from "moment";
export default {
  name: "convenioMarcoCreateView",
  components: {
    GeneralComponent,
    CatalogoObsComponent,
    DocumentosProcesoComponent,
    EtapasProceso,
  },
  data: () => ({
    color_general: "secondary",
    color_obs: "secondary",
    color_doc: "secondary",
    color_etapas: "secondary",
    disabled: false,
    loading: false,
    headers: [
      {
        text: "Etapas",
        align: "center",
        sortable: false,
        value: "nombre",
      },
      {
        text: "Fecha y hora de Inicio",
        align: "center",
        sortable: false,
        value: "fecha_inicio",
      },
      {
        text: "Fecha y hora de Fin",
        align: "center",
        sortable: false,
        value: "fecha_fin",
      },
      {
        text: "Observaciones",
        align: "center",
        sortable: false,
        value: "convenio_fase.observacion",
      },
      {
        text: "Estado",
        align: "center",
        sortable: false,
        value: "estado",
      },
      {
        text: "Acciones",
        align: "center",
        sortable: false,
        value: "acciones",
      },
    ],
  }),
  watch: {
    tab(newVal, oldValue) {
      if (this.tab === 3) {
        if (this.id_agreement_marco !== null) {
          this.getPhasesAgreementList(this.id_agreement_marco);
        }
      }
      if (newVal == 2 && oldValue == 3) {
        this.getDocumentAgreementList(this.id_agreement_marco);
      }
    },
  },
  computed: {
    ...mapState("convenioMarcoAdmin", [
      "isEdit",
      "id_agreement_marco",
      "lista_obs_convenio",
      "lista_document_convenio",
      "lista_fases_convenio",
      "tab",
      "bad_catalogo_obs",
      "bad_documentos",
      "bad_etapas",
      "vigencia_saved",
      "agreement",
    ]),
    tabIndex: {
      get() {
        return this.tab;
      },
      set(value) {
        this.setTab(value);
      },
    },
  },
  methods: {
    ...mapActions("convenioMarcoAdmin", [
      "getEmpleados",
      "postAgreementsMarco",
      "getAgreementMarco",
      "getPhasesAgreementList",
      "postPhaseAgreement",
      "putStatusAgreement",
      "getDocumentAgreementList",
      "saveIdAgreementMarco",
    ]),
    ...mapMutations("convenioMarcoAdmin", [
      "setIsEdit",
      "setTab",
      "setObsAgreementList",
      "setDocumentAgreementList",
      "setBadCatalogoObs",
      "setAlertCatalogoObs",
      "setBadDocumentos",
      "setAlertDocumentos",
      "setBadEtapas",
      "setAlertEtapas",
      "setVigenciaState",
    ]),
    isConfigurated() {
      let count = 0;
      for (let i = 0; i < this.lista_fases_convenio.length; i++) {
        if (this.lista_fases_convenio[i].convenio_fase) {
          count++;
        }
      }
      if (count === this.lista_fases_convenio.length) return true;
      else return false;
    },
    changeColor(n) {
      switch (n) {
        case 1:
          this.color_obs = "";
          this.setBadCatalogoObs(false);
          break;
        case 2:
          this.color_doc = "";
          this.setBadDocumentos(false);
          break;
        case 3:
          this.color_etapas = "";
          this.setBadEtapas(false);
          break;
        default:
          break;
      }
    },
    async getPhasesAgreement() {
      await this.getPhasesAgreementList(this.id_agreement_marco);
    },
    checkFechaHoraEtapa() {
      let fechaHora = moment(
        this.lista_fases_convenio[0].convenio_fase.fecha_inicio
      ).format("YYYY-MM-DD hh:mm");


      return fechaHora < moment().format("YYYY-MM-DD hh:mm");
    },
    async publicar() {
      if (this.id_agreement_marco !== null) {
        if (this.checkFechaHoraEtapa()) {
          this.temporalAlert({
            show: true,
            message:
              "No se puede publicar etapas con fechas anteriores a la actual",
            type: "warning",
          });
        } else {
          this.loading = true;
          const params = {
            id_agreement: this.id_agreement_marco,
            data: {
              id_estado: 4,
            },
          };
          const response = await this.putStatusAgreement(params);
          this.setBadCatalogoObs(false);
          this.setBadDocumentos(false);
          this.setBadEtapas(false);
          if (response.status === 200 || response.status === 201) {
            this.temporalAlert({
              show: true,
              message: "Convenio publicado con éxito",
              type: "success",
            });
            this.$router.push("/convenio-marco-list");
          } else {
            if (this.lista_obs_convenio.length === 0) {
              this.setAlertCatalogoObs(true);
              this.setBadCatalogoObs(true);
              this.color_obs = "red";
            }
            if (this.lista_document_convenio.length === 0) {
              this.setAlertDocumentos(true);
              this.setBadDocumentos(true);
              this.color_doc = "red";
            }
            if (!this.isConfigurated()) {
              this.setAlertEtapas(true);
              this.setBadEtapas(true);
              this.color_etapas = "red";
            }
          }
          this.loading = false;
        }
      }
    },
  },
  async created() {
    if (this.isEdit === false) {
      this.saveIdAgreementMarco(null);
      this.setDocumentAgreementList([]);
      this.setObsAgreementList([]);
      this.setTab(0);
    }
    if (this.id_agreement_marco === null) {
      this.disabled = true;
    } else {
      await this.getPhasesAgreement();
    }
  },
  beforeDestroy() {
    this.setVigenciaState(true);
    this.setIsEdit(false);
  },
};
</script>

<style lang="scss" scoped>
:deep(.theme--light.v-tabs-items) {
  background: transparent !important;
}
:deep(.theme--light.v-data-table) {
  background: transparent !important;
}
.v-tab {
  text-transform: none !important;
}
</style>
