<template>
  <v-dialog max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-checkbox v-model="aprobado" v-bind="attrs" v-on="on"></v-checkbox>
    </template>
    <v-card>
      <v-card-title></v-card-title>
      <v-card-text class="pa-12">
        <h1 class="text-center mt-4 black--text">
          ¿Desea aprobar la solicitud?
        </h1>
        <p class="text-center mt-6 text-h6 font-weight-black black--text">
          Al aprovar las solicitudes pasan a ser parte del agrupador de solicitudes
        </p>
      </v-card-text>
      <v-card-actions class="d-flex justify-center pa-12">
        <v-btn class="mx-6 pa-5" outlined color="btnMinsal">Cancelar</v-btn>
        <v-btn class="mx-6 pa-5 white--text" color="btnMinsal">Aprobar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ModalSolicitudComponent",
  data: () => ({}),
};
</script>