<template>
  <v-row class="flex-column">
    <v-col>
      <p class="text-h6">Solicitud de obras, bienes o servicios</p>
      <p class="text-subtitle-1 mt-n5">UCP</p>
      <p class="text-subtitle-1 mt-n5">Ejercicio 2022</p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "TituloVistaComponent",
};
</script>
