<template>
  <section fluid class="backgroundColor">
    <v-row>
      <v-tabs
        background-color="bgMinsal"
        class="d-flex justify-center"
        v-model="tabsSubMenu"
      >
        <v-tabs-slider color="secondary"></v-tabs-slider>
        <v-tab href="#tab-1" outlined color="secondary">Fondo General</v-tab>
        <v-tab href="#tab-2" outlined color="secondary">Fondos Propios</v-tab>
      </v-tabs>
    </v-row>

    <!-- <v-row > -->
    <v-tabs-items v-model="tabsSubMenu">
      <v-tab-item value="tab-1">
        <v-col class="backgroundColor">
          <fondo-general />
        </v-col>
      </v-tab-item>
      <v-tab-item value="tab-2">
        <v-col class="backgroundColor">
          <fondos-propios />
        </v-col>
      </v-tab-item>
    </v-tabs-items>
    <!-- </v-row> -->
  </section>
</template>
<script>
import fondoGeneral from "./FondoGeneralComponent.vue";
import fondosPropios from "./FondoPropioComponent.vue";
export default {
  name: "InsumoComponent",
  components: {
    fondoGeneral,
    fondosPropios,
  },
  data: () => ({
    tabsSubMenu: null,
  }),
};
</script>
<style>
.backgroundColor {
  background-color: #f4f7fd;
}
</style>
