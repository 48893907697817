<template>
  <section>
    <v-row>
      <v-col cols="12" md="12">
        <p class="text-h6 font-museo-sans mb-0 secondary--text">Convenios marco</p>
        <p class="text-body-2 font-museo-sans primary--text">Ejercicio {{ anioFiscal }}</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="3" lg="3">
        <div class="">
          <v-list dense>
            <v-list-item-group color="secondary">
              <v-list-item
                v-for="(item, index) in obs_tag_list"
                :key="index"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-content @click="filterAgreement(item.obs_nombre)">
                    <v-list-item-title class="filters-text">{{
                      item.obs_nombre
                    }}</v-list-item-title>
                  </v-list-item-content>
                  <v-btn icon v-if="active" @click="filterAgreement(null)">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </v-col>

      <v-col cols="12" sm="12" md="9" lg="9">
        <v-autocomplete
          outlined
          :items="obs_parent_list"
          item-value="id"
          item-text="nombre"
          label="Seleccione un OBS para filtrar los resultados"
          clearable
          @click:clear="filterAgreement(null)"
        >
          <template v-slot:item="{ item }">
            <v-list dense>
              <v-list-item @click="filterAgreement(item.nombre)" three-line>
                <v-list-item-content>
                  <v-list-item-title
                    >{{ item.codigo }} {{ item.nombre }}</v-list-item-title
                  >
                  <v-list-item-subtitle v-if="item.obs_padre">
                    {{ item.obs_padre?.tipo_obs }} :
                    {{ item.obs_padre?.codigo }}
                    {{ item.obs_padre?.nombre }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="item.obs_padre?.obs_padre">
                    {{ item.obs_padre?.obs_padre?.tipo_obs }} :
                    {{ item.obs_padre?.obs_padre?.codigo }}
                    {{ item.obs_padre?.obs_padre?.nombre }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>
        </v-autocomplete>

        <div
          v-if="loadingAgreements"
          class="d-flex justify-center align-center"
        >
          <v-progress-circular
            :size="70"
            :width="7"
            color="secondary"
            indeterminate
          ></v-progress-circular>
        </div>
        
        <div v-else>
          <div
            class="my-2"
            v-for="convenio in lista_convenios"
            :key="convenio.id"
          >
            <detalle-convenio
              @goToInfo="goToInfo"
              :convenio="convenio"
              isProvider
            />
          </div>

          <div v-if="lista_convenios.length == 0">
            <h4 class="text-h6 text-center mt-8 mb-8">No se han encontrado convenios activos</h4>
          </div>

          <v-pagination
            class="my-4"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :total-visible="10"
            :length="totalPageInfo"
            v-model="filters.page"
            @input="paginate"
          />
        </div>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import DetalleConvenio from "@/components/DetalleConvenioComponent.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "conveniosMarcoView",
  components: {
    DetalleConvenio,
  },
  data: () => ({
    loadingAgreements: false,
  }),
  computed: {
    ...mapState(["userInfo", "anioFiscal"]),
    ...mapState("convenioMarcoProveedor", [
      "lista_convenios",
      "filters",
      "obs_parent_list",
      "obs_tag_list",
    ]),
    totalPageInfo() {
      return Math.ceil(this.filters.total_rows / this.filters.per_page);
    },
  },
  methods: {
    ...mapActions("convenioMarcoProveedor", [
      "getListaConvenios",
      "getObsTagList",
      "getObsParentList",
    ]),
    async goToInfo(idConvenio) {
      this.$router.push({
        name: "detalle-convenios-proveedor",
        params: { id: idConvenio },
      });
    },
    paginate() {
      this.getListaConvenios();
    },
    async filterAgreement(value) {
      this.filters.page = 1;
      this.filters.nombre_obs = value;
      await this.getListaConvenios();
    },
    async getAgreements() {
      this.loadingAgreements = true;
      await this.getListaConvenios();
      this.loadingAgreements = false;
    },
  },
  created() {
    this.getAgreements();
    this.getObsTagList();
    this.getObsParentList();
  },
  beforeDestroy() {
    this.filters.page = 1;
  },
};
</script>
<style lang="scss" scoped>

.filters-text{
  font-size: 16px !important;
  font-weight: 500 !important;
}

:deep(.theme--light.v-sheet) {
  background-color: transparent !important;
}

.v-list-item {
  padding-left: 0px !important;
}

.v-list-item--link:before {
  background-color: transparent !important;
}

.v-list-item--active .v-list-item__title {
  font-weight: 700 !important;
  color: var(--v-secondary-base) !important;
}

.v-autocomplete__content.v-menu__content {
  max-width: min-content !important;
}
</style>
