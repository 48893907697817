var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('p',{staticClass:"text-h6 primary--text"},[_vm._v("Configuración de Convenio Marco")]),_c('v-tabs',{attrs:{"background-color":"transparent"},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('v-tab',[_c('v-icon',{staticClass:"mx-2"},[_vm._v("mdi-clipboard-text-outline")]),_vm._v("General ")],1),_c('v-tab',{attrs:{"disabled":_vm.id_agreement_marco === null},on:{"click":function($event){return _vm.changeColor(1)}}},[_c('v-icon',{staticClass:"mx-2",attrs:{"color":_vm.bad_catalogo_obs && _vm.lista_obs_convenio.length === 0
                ? _vm.color_obs
                : ''}},[_vm._v(" mdi-format-list-bulleted ")]),_c('span',{class:_vm.bad_catalogo_obs && _vm.lista_obs_convenio.length === 0
                ? `${_vm.color_obs}--text`
                : ''},[_vm._v(" Catálogo OBS ")])],1),_c('v-tab',{attrs:{"disabled":_vm.id_agreement_marco === null},on:{"click":function($event){return _vm.changeColor(2)}}},[_c('v-icon',{staticClass:"mx-2",attrs:{"color":_vm.bad_documentos && _vm.lista_document_convenio.length === 0
                ? _vm.color_doc
                : ''}},[_vm._v(" mdi-book-open-page-variant-outline ")]),_c('span',{class:_vm.bad_documentos && _vm.lista_document_convenio.length === 0
                ? `${_vm.color_doc}--text`
                : ''},[_vm._v(" Documentos del proceso ")])],1),_c('v-tab',{attrs:{"disabled":_vm.id_agreement_marco === null},on:{"click":function($event){return _vm.changeColor(3)}}},[_c('v-icon',{staticClass:"mx-2",attrs:{"color":_vm.bad_etapas && !_vm.isConfigurated() ? _vm.color_etapas : ''}},[_vm._v("mdi-format-list-numbered")]),_c('span',{class:_vm.bad_etapas && !_vm.isConfigurated() ? `${_vm.color_etapas}--text` : ''},[_vm._v("Etapas del proceso")])],1)],1),_c('v-tabs-items',{model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('v-tab-item',[_c('general-component',{on:{"enablePublicar":function($event){(_vm.disabled = false), _vm.getPhasesAgreement()}}})],1),_c('v-tab-item',[_c('catalogo-obs-component')],1),_c('v-tab-item',[_c('documentos-proceso-component')],1),_c('v-tab-item',[_c('etapas-proceso',{attrs:{"headers":_vm.headers,"items":_vm.lista_fases_convenio},on:{"reload":_vm.getPhasesAgreement}})],1)],1)],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","md":"2"}},[(_vm.agreement?.fecha_publicacion == null && _vm.tabIndex == 3)?_c('v-btn',{attrs:{"loading":_vm.loading,"x-large":"","color":"secondary","disabled":_vm.disabled || _vm.vigencia_saved},on:{"click":function($event){return _vm.publicar()}}},[_vm._v(" Publicar ")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }