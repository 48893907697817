<template>
  <section fluid>
    <v-layout>
      <v-flex>
        <titulo />
        <v-row>
          <v-col cols="12">
            <stats-component :info="itemsFondos" />
          </v-col>
        <v-row class="mt-8">
          <v-col cols="5">
            <v-btn color="secondary" :to="{}" class="mt-5 white--text" small
              >Agregar solicitud</v-btn
            >
          </v-col>
          <v-col class="d-flex">
            <v-select :items="items" label="Estado" outlined
            class="ma-3"></v-select>
            <v-select
              :items="items"
              label="Unidad de Talento Humano"
              outlined
              class="ma-3"
            ></v-select>
          </v-col>
        </v-row>
        <v-col cols="12">
             <v-data-table :headers="headers">
            </v-data-table>
        </v-col>
        </v-row>

      </v-flex>
    </v-layout>
  </section>
</template>
<script>
import titulo from "./components/TituloVistaComponent.vue";
import StatsComponent from "./components/StatsComponent.vue";
export default {
  name: "solictudesView",
  components: {
    titulo,
    StatsComponent,
  },
  data: () => ({
    itemsFondos: [
      {
        id: 1,
        value: "0",
        title: "Aprobadas",
        color: "green accent-3",
      },
      {
        id: 2,
        value: "1",
        title: "Pendientes",
        color: "yellow lighten-1",
      },
      {
        id: 3,
        value: "10",
        title: "Con observaciones",
        color: "green accent-3",
      },
    ],
    headers: [
            {
                text: "Correlativo",
                align: "center",
                value: "correlativo",
                width: "10%",
            },
            {
                text: "Unidad solicitante",
                align: "center",
                value: "unidad_solicitante",
                width: "20%",
            },
            {
                text: "Fecha de creación",
                align: "center",
                value: "fecha_creacion",
                width: "20%",
            },
            {
                text: "Estado",
                align: "center",
                value: "estado",
                width: "20%",
            },
            {
                text: "Acciones",
                align: "center",
                value: "acciones",
                width: "20%",
            },
        ],
    tabsMenu: null,
  }),
};
</script>
<style>
.contentStyle {
  margin-top: 5vh;
}
</style>