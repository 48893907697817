<template>
  <section>
    <v-alert v-if="alert_documentos" type="error" dismissible>
      Debe subir al menos un documento
      <template v-slot:close="{ toggle }">
        <v-btn @click="closeAlert(toggle)" color="white" text>
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </template>
    </v-alert>
    <v-col cols="12" md="6">
      <v-autocomplete
        :items="listadoTipoDocumentoFilter"
        item-text="nombre"
        item-value="id"
        outlined
        label="Tipo de documento *"
        v-model="tipo_documento"
        :error-messages="tipoDocumentoErrorMessage"
        @input="$v.tipo_documento.$touch()"
        @blur="$v.tipo_documento.$touch()"
      />
    </v-col>
    <v-col cols="12" md="6">
      <v-file-input
        hint="Archivo PDF tamaño máximo 250MB"
        persistent-hint
        ref="file_upload"
        outlined
        label="Documento *"
        append-outer-icon="mdi-plus-box-outline"
        accept=".pdf"
        v-model="documento"
        :prepend-icon="null"
        prepend-inner-icon="mdi-paperclip"
        :error-messages="documentoErrorMessage"
        @input="$v.documento.$touch()"
        @blur="$v.documento.$touch()"
        @change="documento ? verificarNombreDocumento() : null"
        @click:append-outer="postDocument()"
      />
    </v-col>
    <v-col>
      <v-data-table
        :headers="headers"
        :items="lista_document_convenio"
        hide-default-footer
      >
        <template v-slot:[`item.id_tipo`]="{ item }">
          {{ getDocumentName(item.id_tipo) }}
        </template>
        <template v-slot:[`item.acciones`]="{ item }">
          <v-icon :disabled="id_estado_convenio === 4 && item.id_tipo === 1" color="primary" @click="deleteDocumento(item.id)">
            mdi-delete-outline
          </v-icon>
        </template>
      </v-data-table>
    </v-col>
    <div>
      <action-button-component :step="3" @documentos="postDocument" />
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import ActionButtonComponent from "./ActionButtonComponent.vue";
import LegacyValidations from "@/utils/legacy-validations";
import { required } from "vuelidate/lib/validators";

const file_type_validation = (value, vm) => {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
};

export default {
  name: "DocumentosProcesoComponent",
  components: {
    ActionButtonComponent,
  },
  data: () => ({
    tipo_documento: "",
    documento: null,
    headers: [
      {
        text: "Tipo de documento",
        align: "center",
        sortable: false,
        value: "id_tipo",
      },
      {
        text: "Documento",
        align: "center",
        sortable: false,
        value: "nombre",
      },
      {
        text: "Acciones",
        align: "center",
        sortable: false,
        value: "acciones",
      },
    ],
  }),
  validations() {
    return {
      tipo_documento: {
        required,
      },
      documento: {
        required,
        file_size_validation: LegacyValidations.maxFileSizeRule(),
        file_type_validation,
      },
    };
  },
  computed: {
    ...mapState("convenioMarcoAdmin", [
      "id_agreement_marco",
      "id_estado_convenio",
      "lista_document_type",
      "lista_document_convenio",
      "bad_documentos",
      "alert_documentos",
    ]),
    listadoTipoDocumentoFilter() {
      return this.lista_document_type.filter((type) => {
        return !this.lista_document_convenio.some(
          (item) => item.id_tipo === type.id
        );
      });
    },
    tipoDocumentoErrorMessage() {
      const errors = [];
      if (!this.$v.tipo_documento.$dirty) return errors;
      !this.$v.tipo_documento.required &&
        errors.push("Debe seleccionar un tipo de documento");
      return errors;
    },
    documentoErrorMessage() {
      const errors = [];
      if (!this.$v.documento.$dirty) return errors;
      !this.$v.documento.required && errors.push("Debe cargar un documento");
      !this.$v.documento.file_type_validation &&
        errors.push("El formato del archivo tiene que ser .pdf");
      !this.$v.documento.file_size_validation && LegacyValidations.pushToErrorsArray(errors);
      //!this.$v.documento.file_name_validation && errors.push("Ya existe un documento con ese nombre")
      return errors;
    },
  },
  methods: {
    ...mapActions("convenioMarcoAdmin", [
      "getDocumentType",
      "getDocumentAgreementList",
      "postAgreementDocument",
      "deleteAgreementDocument",
    ]),
    ...mapMutations("convenioMarcoAdmin", [
      "setDocumentAgreementList",
      "setBadDocumentos",
      "setAlertDocumentos",
    ]),
    clean() {
      this.tipo_documento = "";
      this.documento = null;
      this.$v.tipo_documento.$reset();
      this.$v.documento.$reset();
    },
    closeAlert(toggle){
      this.setAlertDocumentos(false)
      toggle()
    },
    getDocumentName(id) {
      const data = this.lista_document_type.find((item) => item.id === id);
      return data.nombre;
    },
    verificarNombreDocumento() {
      this.lista_document_convenio.some((item) => {
        if (item.ruta === this.documento.name) {
          this.temporalAlert({
            show: true,
            message: "Ya existe un documento con ese nombre",
            type: "error",
          });
          this.$refs.file_upload.reset();
          this.documento = null;
          return;
        }
      });
    },
    async postDocument() {
      if (this.id_agreement_marco !== null) {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          const params = {
            id: this.id_agreement_marco,
            data: {
              id_tipo: this.tipo_documento,
              documento: this.documento,
            },
          };
          const response = await this.postAgreementDocument(params);
          this.setBadDocumentos(false)
          if (response.status === 201) {
            this.temporalAlert({
              show: true,
              message: "Documento creado exitosamente",
              type: "success",
            });
            this.clean();
            this.getDocumentAgreementList(this.id_agreement_marco);
          }
        }
      } else {
        this.temporalAlert({
          show: true,
          message:
            "Debe seleccionar un convenio o crear uno nuevo para agregar documentos",
          type: "error",
        });
      }
    },
    async deleteDocumento(id) {
      const response = await this.deleteAgreementDocument(id);
      if (response.status === 204) {
        this.temporalAlert({
          show: true,
          message: "Documento borrado",
          type: "success",
        });
        this.getDocumentAgreementList(this.id_agreement_marco);
      }
    },
  },
  async created() {
    this.getDocumentType();
    if (this.id_agreement_marco !== null) {
      this.getDocumentAgreementList(this.id_agreement_marco);
    }
  },
};
</script>
