<template>
  <section fluid>
    <v-layout>
      <v-flex>
        <titulo />
        <v-row>
          <v-tabs v-model="tabsMenu" background-color="bgMinsal">
            <v-tabs-slider color="secondary"></v-tabs-slider>
            <v-tab href="#tab-1" class="secondary--text"
              ><v-icon>mdi-package-variant-closed </v-icon>Insumos</v-tab
            >
            <v-tab href="#tab-2" class="secondary--text"
              ><v-icon>mdi-account-multiple</v-icon>Administradores de
              contrato</v-tab
            >
          </v-tabs>
        </v-row>
        <v-row>
          <v-tabs-items style="width: 100%" v-model="tabsMenu">
            <v-tab-item value="tab-1">
              <insumos />
            </v-tab-item>
            <v-tab-item value="tab-2">
              <administradores-de-contrato />
            </v-tab-item>
          </v-tabs-items>
        </v-row>
        <v-row class="">
          <chat></chat>
        </v-row>
      </v-flex>
    </v-layout>
  </section>
</template>
<script>
import titulo from "./components/TituloVistaComponent.vue";
import insumos from "./components/InsumoComponent.vue";
import administradoresDeContrato from "./components/AdministradorContratoComponent.vue";
import Chat from "../../components/ChatComponent.vue";
export default {
  name: "insumosListaView",
  components: {
    titulo,
    insumos,
    administradoresDeContrato,
    Chat,
  },
  data: () => ({
    tabsMenu: null,
  }),
};
</script>
