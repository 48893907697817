<template>
  <section class="mx-3">
    <!-- title -->
    <div class="my-6 d-flex flex-column text-center text-sm-left">
      <span class="text-h5 secondary--text" v-text="'Fondos para método de baja cuantía'" />
    </div>
    <!-- body -->
    <v-row>
      <v-col cols="12" class="d-flex justify-center justify-sm-end">
        <v-btn class="button-extra text-no-style mt-4 mt-sm-0 mx-8 mx-sm-4" color="secondary"
          @click="(show_modal_detail_var = true), ($refs.monto_fondo_var?.updateValue(''))">
          Agregar
        </v-btn>
      </v-col>
      <v-col cols="12">
        <span class="text--disabled text-body-2 pl-2" v-text="'Listado de fondos'" />
        <!--  componente dinamico global de tabla con paginacion -->
        <data-table-component :actions="ACTIONS_CONST" :headers="HEADERS_CONST" :items="list_fondos_var"
          :show_actions="true" :total_registros="total_rows_var" @editFondoEvent="OpenModalDetailFtn"
          @paginar="PaginateFtn" v-models:pagina="pagination_var.page" v-models:select="pagination_var.per_page">
          <template v-slot:[`item.monto_establecido`]="{ item }">
            {{
              Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(item.monto_establecido)
            }}
          </template>
          <template v-slot:[`item.monto_disponible`]="{ item }">
            {{
              Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(item.monto_disponible)
            }}
          </template>
          <template v-slot:[`item.anio_fiscal`]="{ item }">
            {{ item.anio_fiscal ?? '-' }}
          </template>
        </data-table-component>
      </v-col>
    </v-row>

    <!-- modal -->
    <v-dialog v-model="show_modal_detail_var" persistent max-width="800">
      <v-card>
        <div class="headerIcon">
          <v-icon class="secondary--text" @click="CloseModalDetailFtn">
            mdi-close
          </v-icon>
        </div>
        <v-card-text>
          <!-- registrar fondo -->
          <v-row class="mx-sm-6">
            <v-col cols="12">
              <span class="text-h6 secondary--text">Registrar fondo</span>
            </v-col>
            <v-col cols="12" sm="7">
              <v-select
                v-model="anio_fondo_var"
                label="Año"
                placeholder="Seleccione un año"
                :loading="cargandoCatalogoAnios" 
                :items="catalogoAnios"
                item-text="anio"
                item-id="id"
                outlined
              >

              </v-select>

              <v-text-field label="Nombre *" outlined v-model="nombre_fondo_var" @input="$v.nombre_fondo_var.$touch()"
                @blur="$v.nombre_fondo_var.$touch()" :error-messages="nombreFondoError" clearable maxlength="75" />

              <div>
                <money-text-field-component label="Monto asignado *" v-model="monto_fondo_var" ref="monto_fondo_var"
                  @blur="$v.monto_fondo_var.$touch" :error-messages="montoFondoError" />
              </div>

              <!-- <div v-if="SNAPSHOT_CONST.nombre">
                <p class="mb-0">Monto asignado</p>
                <p class="text-h6 secondary--text">$ {{
                  Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(monto_fondo_var)
                }}</p>
              </div> -->
            </v-col>
            <v-col cols="12" sm="6" class="d-flex justify-center justify-sm-end" v-if="false">
              <div>
                <v-switch :disabled="!id_fondo_selected_var" color="success" :ripple="false" v-model="estado_fondo_var"
                  hide-details :true-value="1" :false-value="2">
                  <template #prepend>
                    <v-label class="pl-6"> Estado </v-label>
                  </template>
                </v-switch>
                <div class="pt-2">
                  <v-chip v-if="estado_fondo_var == 1" color="success" dark class="mx-2" label small>
                    Habilitado
                  </v-chip>
                  <v-chip v-else color="error" class="white--text" label small>
                    Deshabilitado
                  </v-chip>
                </div>
              </div>
            </v-col>
            <v-col cols="12">
              <v-textarea label="Motivo *" outlined rows="4" auto-grow v-model="motivo_fondo_var"
                @input="$v.motivo_fondo_var.$touch()" @blur="$v.motivo_fondo_var.$touch()"
                :error-messages="motivoFondoError" clearable maxlength="250" />
            </v-col>
          </v-row>

          <!-- acciones -->
          <div class="pl-4 mb-6" v-if="SNAPSHOT_CONST.nombre">
            <v-btn class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4" color="secondary" outlined
              @click="CloseModalDetailFtn">
              Cancelar
            </v-btn>
            <v-btn class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4" color="secondary"
              @click="handlerSaveFtn">
              {{ id_fondo_selected_var ? "Actualizar" : "Registrar" }}
            </v-btn>
            <v-divider class="my-6" />
          </div>

          <!-- administrar fondo -->
          <v-row class="mx-sm-6">
            <v-col cols="12" class="pt-0">
              <span class="text-h6 secondary--text">Administrador del fondo</span>
            </v-col>
            <v-col cols="12" sm="7">
              <v-row>
                <v-col cols="10">
                  <v-text-field label="Correo Electrónico *" outlined color="secondary" v-model="email_admin_var"
                    @input="$v.email_admin_var.$touch()" @blur="$v.email_admin_var.$touch()"
                    :error-messages="emailAdminError" clearable />
                </v-col>
                <v-col cols="2" class="text-center">
                  <v-btn :disabled="email_admin_var == null" icon class="mt-1" x-large @click="handlerAddFtn">
                    <v-icon color="secondary" large> mdi-plus-box </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <DataTableComponent
                :headers="HEADERS_ADM"
                :items="stack_emails_var"
                :tiene_paginacion="false"
                >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn icon medium @click="DeleteAdminFondoFtn(item.id)">
                    <v-icon color="error"> mdi-delete </v-icon>
                  </v-btn>
                </template>
              </DataTableComponent>
            </v-col>
          </v-row>
          <!-- acciones -->
          <v-row cols="12" class="d-flex flex-column flex-sm-row mx-sm-4 mb-6" v-if="!SNAPSHOT_CONST.nombre">
            <v-btn class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4" color="secondary" outlined
              @click="CloseModalDetailFtn">
              Cancelar
            </v-btn>
            <v-btn class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4" color="secondary"
              @click="handlerSaveFtn">
              {{ id_fondo_selected_var ? "Actualizar" : "Registrar" }}
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
// importacion de componentes globales
import DataTableComponent from "../../components/DataTableComponent.vue";
import MoneyTextFieldComponent from "../../components/MoneyTextFieldComponent.vue";

// importacion de librerias
import { required, requiredIf, helpers } from "vuelidate/lib/validators";
import { mapState } from "vuex";

const email = helpers.regex('email', /^[a-zA-Z\d]+[a-zA-Z\d._-]*@([a-zA-Z\d]+(-|.)?){0,3}[a-zA-Z\d]+(\.[a-zA-Z\d]+)?(\.[a-zA-Z\d]{2,})$/);
const txtField = helpers.regex("txtField", /^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ0-9 -]*$/);
const txtArea = helpers.regex(
  "txtArea",
  /^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ0-9 ()/.,\n:;-]*$/
);

export default {
  components: {
    DataTableComponent,
    MoneyTextFieldComponent,
  },

  validations: {
    nombre_fondo_var: {
      required,
      txtField,
    },
    monto_fondo_var: {
      required,
    },
    motivo_fondo_var: {
      required,
      txtArea,
    },
    email_admin_var: {
      // requerido si el stack de fondos esta vacio
      requiredIf: requiredIf(function (inst) {
        return inst?.stack_emails_var?.length === 0;
      }),
      email,
    },
  },

  data: () => ({
    // constantes
    HEADERS_CONST: [
      {
        align: "center",
        text: "Correlativo",
        value: "correlativo",
      },
      {
        align: "center",
        text: "Nombre",
        value: "nombre",
      },
      {
        align: "center",
        text: "Monto asignado ($)",
        value: "monto_establecido",
      },
      {
        align: "center",
        text: "Monto disponible ($)",
        value: "monto_disponible",
      },
      {
        align: "center",
        text: "Año",
        value: "anio_fiscal",
      },
      {
        align: "center",
        text: "Acciones",
        value: "actions",
        sortable: false,
      },
    ],

    /*
     * WARNING: este array es parte de una props para el componente DataTableComponent,
     * por lo que no se debe modificar su estructura, si desea agregar condiciones especiales,
     * favor de crear una funcion del tipo init para cargar las acciones, o simplemente
     * trabajar con slots
     */
    ACTIONS_CONST: [
      {
        tooltip: {
          text: "Editar fondo",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-pencil",
        },
        eventName: "editFondoEvent",
      },
    ],

    SNAPSHOT_CONST: {},

    // variables
    list_fondos_var: [],
    pagination_var: {
      page: 1,
      per_page: 10,
    },
    total_rows_var: 0,

    stack_emails_var: [],
    show_modal_detail_var: false,
    id_fondo_selected_var: null,

    // v -> modal
    anio_fondo_var: null,
    nombre_fondo_var: null,
    monto_fondo_var: null,
    estado_fondo_var: 1, // 1 = habilitado, 2 = deshabilitado
    motivo_fondo_var: null,
    email_admin_var: null,

    // Catalogo anios
    catalogoAnios: [],
    cargandoCatalogoAnios: false,
  }),

  computed: {
    // mapeo de estados
    ...mapState({
      id_institucion_cmp: (state) => state?.selectedUnidad?.instituciones?.id,
    }),

    // errores de validacion
    nombreFondoError() {
      const errors = [];
      if (!this.$v.nombre_fondo_var.$dirty) return errors;
      !this.$v.nombre_fondo_var.required &&
        errors.push("El nombre es requerido");
      !this.$v.nombre_fondo_var.txtField &&
        errors.push("El nombre no es válido");
      return errors;
    },
    montoFondoError() {
      const errors = [];
      if (!this.$v.monto_fondo_var.$dirty) return errors;
      !this.$v.monto_fondo_var.required &&
        errors.push("El monto asignado es requerido");
      return errors;
    },
    motivoFondoError() {
      const errors = [];
      if (!this.$v.motivo_fondo_var.$dirty) return errors;
      !this.$v.motivo_fondo_var.required &&
        errors.push("El motivo es requerido");
      !this.$v.motivo_fondo_var.txtArea &&
        errors.push("El motivo no es válido");
      return errors;
    },
    emailAdminError() {
      const errors = [];
      if (!this.$v.email_admin_var.$dirty) return errors;
      !this.$v.email_admin_var.requiredIf &&
        errors.push("El correo electrónico es requerido");
      !this.$v.email_admin_var.email &&
        errors.push("Ingresa un correo electrónico con formato válido");
      return errors;
    },
    HEADERS_ADM(){
      const headers = [
        {
          align: "center",
          text: "Nombre Empleado",
          value: "nombre",
          sortable: false,
        },
        {
          align: "center",
          text: "Email",
          value: "email",
          sortable: false,
        },
      ]
      if(this.stack_emails_var?.length > 1){
        headers.push({
          align: "center",
          text: "Acciones",
          value: "actions",
          sortable: false,
        })
      }
      return headers
    },
  },

  methods: {
    // Funciones regulares

    /**
     *
     * @description funcion para limpiar los inputs del modal
     */
    CleanInputsFtn() {
      this.nombre_fondo_var = null;
      this.motivo_fondo_var = null;
      this.email_admin_var = null;
      this.stack_emails_var = [];
    },

    /**
     *
     * @description funcion para cerrar el modal de detalle
     */
    CloseModalDetailFtn() {
      this.show_modal_detail_var = false;
      this.id_fondo_selected_var = null;
      this.SNAPSHOT_CONST = {};

      this.$refs.monto_fondo_var?.updateValue('');

      this.CleanInputsFtn();
    },

    /**
     *
     * @description funcion para abrir el modal de detalle
     * @param {Object} item
     */
    OpenModalDetailFtn(item) {
      this.show_modal_detail_var = true;
      this.id_fondo_selected_var = Number(item.id);
      this.FetchFondoByIdFtn();
    },

    /**
     *
     * @description funcion para validar si un administrador ya esta en el stack
     */
    verifyStackFtn() {
      // verificar si el empleado ya esta en el stack
      const check_repeat_sc_var = this.stack_emails_var.find(
        (item) => item.email == this.email_admin_var
      );

      if (check_repeat_sc_var) {
        this.temporalAlert({
          message: "Empleado ya agregado",
          show: true,
          type: "warning",
        });
        this.email_admin_var = null;
        return false;
      } else {
        return true;
      }
    },

    /**
     *
     * @description funcion tipo handler para las acciones del boton de guardar
     */
    handlerSaveFtn() {
      if (this.id_fondo_selected_var) {
        this.UpdateFondoFtn();
      } else {
        this.CreateFondoFtn();
      }
    },

    /**
     *
     * @description funcion tipo handler para las acciones del boton de agregar 
     */
    handlerAddFtn() {
      if (this.id_fondo_selected_var) {
        this.AddAdminFondoFtn();
      } else {
        this.FetchAdminFondoFtn();
      }
    },

    // Funciones Asincronas

    // <-- Admin Fondo -->

    /**
     *
     * @description funcion para agregar un administrador al fondo ( API )
     */
    async AddAdminFondoFtn() {
      if (this.$v.email_admin_var.$invalid) {
        this.$v.email_admin_var.$touch();
        return;
      } else {
        if (!this.verifyStackFtn()) return;


        const INFO_SC_CONST = await this.services.BajaCuantia.getEmpleadoByEmail(
          { email: this.email_admin_var }
        );


        if (INFO_SC_CONST.status === 200) {

          const RESP_SC_CONST =
            await this.services.BajaCuantia.postAddAdminFondo(
              this.id_fondo_selected_var,
              INFO_SC_CONST.data.id
            );


          if (RESP_SC_CONST.status === 201) {
            const { data } = RESP_SC_CONST;
            this.temporalAlert({
              message: "Empleado agregado",
              show: true,
              type: "success",
            });
            this.stack_emails_var.push({
              id: data.id,
              email: data.email,
              nombre: data.nombre,
            });
            this.email_admin_var = null;
          }
        } else {
          this.temporalAlert({
            message: "Empleado no encontrado",
            show: true,
            type: "error",
          });
        }
      }
    },

    /**
     *
     * @description funcion para eliminar del fondo ( API )
     * @param {Number} id_admin_param
     */
    async DeleteAdminFondoFtn(id_admin_param) {
      if (this.stack_emails_var.length === 1) {
        this.temporalAlert({
          message: "El fondo debe tener al menos un administrador",
          show: true,
          type: "warning",
        });
        return;
      }


      const RESP_SC_CONST = await this.services.BajaCuantia.deleteAdminFondo(
        // this.id_fondo_selected_var,
        id_admin_param
      );


      if (RESP_SC_CONST.status === 204) {
        this.temporalAlert({
          message: "Administrador eliminado",
          show: true,
          type: "success",
        });
        this.stack_emails_var = this.stack_emails_var.filter(
          (item) => item.id !== id_admin_param
        );
      } else {
        this.temporalAlert({
          message: "Administrador no eliminado",
          show: true,
          type: "error",
        });
      }
    },

    /**
     *
     * @description funcion para obtener la informacion de un empleado por su dui ( API )
     */
    async FetchAdminFondoFtn() {
      if (this.$v.email_admin_var.$invalid) {
        this.$v.email_admin_var.$touch();
        return;
      } else {
        if (!this.verifyStackFtn()) return;


        const RESP_SC_CONST = await this.services.BajaCuantia.getEmpleadoByEmail(
          { email: this.email_admin_var }
        );


        if (RESP_SC_CONST.status === 200) {
          this.temporalAlert({
            message: "Empleado encontrado",
            show: true,
            type: "success",
          });
          this.stack_emails_var.push(RESP_SC_CONST.data);
        } else {
          this.temporalAlert({
            message: "Empleado no encontrado",
            show: true,
            type: "error",
          });
        }
        this.email_admin_var = null;
      }
    },

    // <-- Fondos -->
    /**
     *
     * @description funcion para crear un fondo ( API )
     */
    async CreateFondoFtn() {
      if (
        this.$v.nombre_fondo_var.$invalid ||
        this.$v.motivo_fondo_var.$invalid
      ) {
        this.$v.$touch();
        this.temporalAlert({
          message: "Los campos marcados son requeridos",
          show: true,
          type: "warning",
        });
        return;
      } else {

        if (this.stack_emails_var == 0) {
          this.temporalAlert({
            message: "Debe agregar al menos un administrador",
            show: true,
            type: "warning",
          });
          return;
        }


        const RESP_SC_CONST = await this.services.BajaCuantia.postAddFondo({
          nombre: this.nombre_fondo_var,
          anio_fiscal: this.anio_fondo_var,
          // estado: this.estado_fondo_var,
          id_institucion: this.id_institucion_cmp,
          motivo: this.motivo_fondo_var,
          monto_establecido: Number(this.monto_fondo_var),
          administradores: this.stack_emails_var.map((item) => item.id),
        });


        if (RESP_SC_CONST.status === 201) {
          this.temporalAlert({
            message: "Fondo creado correctamente",
            show: true,
            type: "success",
          });
          this.FetchListFondosFtn(true);
        } else {
          this.temporalAlert({
            message: "Error al crear el fondo",
            show: true,
            type: "error",
          });
        }
        this.CloseModalDetailFtn();
      }
    },

    /**
     *
     * @description funcion para actualizar un fondo ( API )
     */
    async UpdateFondoFtn() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.temporalAlert({
          message: "Los campos marcados son requeridos",
          show: true,
          type: "warning",
        });
        return;
      } else {
        const BODY_ARGM_SC_CONST = {
          anio_fiscal: this.anio_fondo_var,
          nombre: this.nombre_fondo_var,
          estado: this.estado_fondo_var,
          motivo: this.motivo_fondo_var,
          monto_asignado: Number(this.monto_fondo_var),
          administradores: this.stack_emails_var.map((item) => item.id),
        };

        let check_similar_sc_var =
          JSON.stringify(this.SNAPSHOT_CONST) ===
          JSON.stringify(BODY_ARGM_SC_CONST);

        if (check_similar_sc_var) {
          this.temporalAlert({
            message: "No se han realizado cambios",
            show: true,
            type: "info",
          });
          return;
        } else {

          const RESP_SC_CONST = await this.services.BajaCuantia.putEditFondo(
            this.id_fondo_selected_var,
            BODY_ARGM_SC_CONST
          );


          if (RESP_SC_CONST.status === 200) {
            this.temporalAlert({
              message: "Fondo actualizado correctamente",
              show: true,
              type: "success",
            });
            this.FetchListFondosFtn();
          } else {
            this.temporalAlert({
              message: "Error al actualizar el fondo",
              show: true,
              type: "error",
            });
          }
          this.CloseModalDetailFtn();
        }
      }
    },

    /**
     *
     * @description funcion para obtener un fondo por su id ( API )
     */
    async FetchFondoByIdFtn() {
      if (this.id_fondo_selected_var) {

        const RESP_SC_CONST = await this.services.BajaCuantia.getFondoById(
          this.id_fondo_selected_var
        );

        if (RESP_SC_CONST.status === 200) {
          const { data } = RESP_SC_CONST;

          this.anio_fondo_var = data.anio_fiscal;
          this.nombre_fondo_var = data.nombre;
          this.estado_fondo_var = data.estado;
          this.motivo_fondo_var = data.motivo;
          this.stack_emails_var = data.administradores;
          this.$refs.monto_fondo_var.updateValue(Number(data.monto_establecido));

          this.SNAPSHOT_CONST = {
            anio: data.anio,
            nombre: data.nombre,
            estado: data.estado,
            motivo: data.motivo,
            monto_asignado: Number(data.monto_establecido),
            administradores: data.administradores.map((item) => item.id),
          };
        }

      }
    },

    /**
     *
     * @description funcion para paginar la tabla de fondos ( API )
     * @param {Object} filters_param
     */
    async PaginateFtn(filters_param) {
      const { cantidad_por_pagina, pagina } = filters_param;

      this.pagination_var.per_page = cantidad_por_pagina;
      this.pagination_var.page = pagina;

      this.FetchListFondosFtn();
    },

    /**
     *
     * @description funcion para obtener la lista de fondos ( API )
     * @param {Boolean} init_param
     */
    async FetchListFondosFtn(init_param = false) {

      if (init_param) {
        this.pagination_var.page = 1;
        this.pagination_var.per_page = 10;
      }

      const filters_sc_var = {
        page: this.pagination_var.page,
        per_page: this.pagination_var.per_page,
      };
      const RESP_SC_CONST = await this.services.BajaCuantia.getListFondos(
        filters_sc_var
      );

      if (RESP_SC_CONST.status === 200) {
        const { data, headers } = RESP_SC_CONST;
        this.list_fondos_var = data;

        this.pagination_var.page = Number(headers.page);
        this.pagination_var.per_page = Number(headers.per_page);
        this.total_rows_var = Number(headers.total_rows);

        this.list_fondos_var.forEach((item, index) => {
          item.correlativo = index + 1;
        });
      }

    },
    async cargarCatalogoAnios() {
      try {
        this.cargandoCatalogoAnios = true;
        const { data } = await this.services.Auth.obtenerAniosFiscales();
        this.catalogoAnios = data;
      } catch (error) {}
      finally {
        this.cargandoCatalogoAnios = false;
      }
    },
  },
  watch: {
    show_modal_detail_var(value) {
      if (value) return;
      
      this.$v.$reset();
    },
  },
  created() {
    this.FetchListFondosFtn(true);
    this.cargarCatalogoAnios();
  },
};
</script>