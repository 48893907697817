<template>
  <section>
    <v-row class="mt-1">
      <v-col cols="12" md="10">
        <v-text-field
          outlined
          label="Nombre de convenio *"
          color="secondary"
          v-model="name"
          :error-messages="nameErrorMessage"
          @input="$v.name.$touch()"
          @blur="$v.name.$touch()"
          @change="editMode ? putGeneral('nombre', name) : addData('name')"
        />
      </v-col>
    </v-row>
    <v-row class="mt-1">
      <v-col cols="12" md="10">
        <v-textarea
          outlined
          label="Justificación de convenio *"
          color="secondary"
          v-model="justification"
          :error-messages="justificationErrorMessage"
          @input="$v.justification.$touch()"
          @blur="$v.justification.$touch()"
          @change="editMode ? putGeneral('justificacion', justification) : addData('justification')"
        />
      </v-col>
    </v-row>
    <v-row class="mt-1">
      <v-col cols="12" md="10">
        <v-autocomplete
          :items="lista_empleados"
          v-model="id_employee"
          item-value="id"
          item-text="primer_nombre"
          outlined
          label="Técnico asignado *"
          :error-messages="tecnicoErrorMessage"
          @input="$v.id_employee.$touch()"
          @blur="$v.id_employee.$touch()"
          @change="editMode ? putGeneral('empleado', id_employee) : addData('tecnico')"
        >
          <template slot="selection" slot-scope="data">
            {{
              data.item.primer_nombre !== null &&
              data.item.primer_nombre !== undefined
                ? data.item.primer_nombre
                : ""
            }}
            {{
              data.item.segundo_nombre !== null &&
              data.item.segundo_nombre !== undefined
                ? data.item.segundo_nombre
                : ""
            }}
            {{
              data.item.tercer_nombre !== null &&
              data.item.tercer_nombre !== undefined
                ? data.item.tercer_nombre
                : ""
            }}
            {{
              data.item.primer_apellido !== null &&
              data.item.primer_apellido !== undefined
                ? data.item.primer_apellido
                : ""
            }}
            {{
              data.item.segundo_apellido !== null &&
              data.item.segundo_apellido !== undefined
                ? data.item.segundo_apellido
                : ""
            }}
            {{
              data.item.tercer_apellido !== null &&
              data.item.tercer_apellido !== undefined
                ? data.item.tercer_apellido
                : ""
            }}
          </template>
          <template v-slot:[`item`]="{ item }">
            {{ getEmployeeName(item) }}
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row class="mt-1">
      <v-col cols="12" md="3">
        <v-img
          v-if="image !== null && image !== undefined"
          :src="image"
          max-width="600px"
          max-height="600px"
        />
        <v-icon v-else size="100px" color="primary">
          mdi-file-image-plus-outline
        </v-icon>
      </v-col>
    </v-row>
    <v-row class="mt-1">
      <v-col cols="12" md="10">
        <v-file-input
          hint="Imagen JPG o PNG tamaño máximo 512KB"
          persistent-hint
          v-model="file"
          accept="image/png, image/jpeg"
          enctype="multipart/form-data"
          label="Adjuntar imagen png o jpg con dimensiones 256 de largo x 192 de altura."
          :prepend-icon="null"
          prepend-inner-icon="mdi-paperclip"
          :error-messages="imageErrorMessage"
          @input="$v.file.$touch()"
          @blur="$v.file.$touch()"
          @change="editMode ? enabledButton() : addData('image'), cargarLogo($event)"
          @click:clear="clearLogo()"
        />
      </v-col>
      <v-btn 
        v-if="showImageButton" 
        :disabled="disabledImageButton" 
        color="secondary"
        @click="putGeneral('imagen', file)"
      >
        subir
      </v-btn>
    </v-row>
    <div>
      <action-button-component
        :step="1"
        @general="postGeneral()"
      />
    </div>
  </section>
</template>

<script>
import ActionButtonComponent from "./ActionButtonComponent.vue";
import { required, maxLength, requiredIf } from "vuelidate/lib/validators";
import { mapState, mapActions, mapMutations } from "vuex";
import moment from "moment";

const file_type_validation = (value, vm) => {
  if (!value) {
    return true;
  }
  let extension = /(\.jpg|\.jpeg|\.png)$/i;
  let file = value;
  return extension.test(file.name);
};

function file_size_validation(value){
  if(!value){
    return true
  }
  if(this.width === 256 && this.height === 192){
    return true
  }
  return false
}

export default {
  name: "GeneralComponent",
  components: {
    ActionButtonComponent,
  },
  data: () => ({
    editMode: false,
    showImageButton: false,
    disabledImageButton: true,
    disableButton: false,
    dialogFechaInicio: false,
    dialogFechaFin: false,
    fecha_inicio: null,
    fecha_fin: null,
    name: "",
    justification: "",
    id_employee: "",
    employee: "",
    file: null,
    image: null,
    width: '',
    height: '',
    fecha_actual: "",
    config_calendar: {
      visibility: 'click',
    },
  }),
  validations: {
    name: {
      required: requiredIf(function(){
        return !this.editMode
      }),
      maxLength: maxLength(50)
    },
    justification: {
      required: requiredIf(function(){
        return !this.editMode
      }),
      maxLength: maxLength(250)
    },
    id_employee: {
      required: requiredIf(function(){
        return !this.editMode
      }),
    },
    file: {
      file_type_validation,
      file_size_validation,
    },
  },
  computed: {
    ...mapState("utils", ["loader"]),
    ...mapState("convenioMarcoAdmin", [
      "id_agreement_marco",
      "agreement",
      "lista_empleados",
      "general_data",
    ]),
    nameErrorMessage() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Campo requerido");
      !this.$v.name.maxLength && errors.push("Máximo 50 caracteres")
      return errors;
    },
    justificationErrorMessage() {
      const errors = [];
      if (!this.$v.justification.$dirty) return errors;
      !this.$v.justification.required && errors.push("Campo requerido");
      !this.$v.justification.maxLength && errors.push("Máximo 250 caracteres")
      return errors;
    },
    tecnicoErrorMessage() {
      const errors = [];
      if (!this.$v.id_employee.$dirty) return errors;
      !this.$v.id_employee.required && errors.push("Campo requerido");
      return errors;
    },
    imageErrorMessage() {
      const errors = [];
      if (!this.$v.file.$dirty) return errors;
      !this.$v.file.file_type_validation &&
        errors.push("El archivo debe tener extensión .jpg, .jpeg o .png");
      !this.$v.file.file_size_validation &&
        errors.push("Las dimensiones de la imagen no son correctas")
      return errors;
    },
  },
  methods: {
    ...mapActions("convenioMarcoAdmin", [
      "saveIdAgreementMarco",
      "getEmpleados",
      "loadGeneralData",
      "postAgreementsMarco",
      "putAgreementMarco",
      "getAgreementMarco",
      "getImageAgreement",
    ]),
    ...mapMutations("convenioMarcoAdmin", ["setStartDate", "clearGeneralData","setVigenciaState"]),
    ...mapMutations("utils", ["setLoader"]),
    obtenerImagen(e) {
      this.cargarLogo((this.file = e.target.files[0]));
    },
    cargarLogo(f) {
      if (f !== null) {
        const reader = new FileReader();
        reader.readAsDataURL(f);
        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;
          img.onload = (g) => {
            this.width = g.target.width
            this.height = g.target.height
            if (g.target.width === 256 && g.target.height === 192) {
              this.image = e.target.result;
            }else{
              this.image = null
            }
          };
        };
      }
    },
    clearLogo() {
      this.file = null;
      this.image = null;
      this.showImageButton = false
      this.disabledImageButton = true
    },
    enabledButton(){
      this.$v.$touch()
      if(!this.$v.$params.file){
        this.showImageButton = true
        this.disabledImageButton = false
      }
    },
    async postGeneral() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.setLoader(true);
        const response = await this.postAgreementsMarco();
        if (response.status === 201) {
          this.temporalAlert({
            show: true,
            message: "Convenio creado con éxito",
            type: "success",
          });
          this.saveIdAgreementMarco(response.data.id);
          this.editMode = true;
          const date = new Date(response.data.fecha_inicio);
          date.setDate(date.getDate() - 1);
          const date2 = date.toISOString();
          // this.setStartDate(date2);
          this.$emit('enablePublicar')
        } else {
          this.temporalAlert({
            show: true,
            message: response.data.message,
            type: "error",
          });
        }
        this.setLoader(false);
      }
    },
    async putGeneral(clave, valor) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if(valor === '' || valor === null) return;
          this.setLoader(true);
          this.clearGeneralData()
          if(clave === 'empleado'){
            this.loadGeneralData({
            property: "id_empleado",
            data: valor,
          });
          let nom = this.lista_empleados.find(
            (item) => item.id === valor
          );
          this.loadGeneralData({
            property: "employee",
            data: this.getEmployeeName(nom),
          });
        }else{
          this.loadGeneralData({ property: clave, data: valor})
        }
        const params = {
          id: this.id_agreement_marco,
          agreement: this.general_data,
        };
        const response = await this.putAgreementMarco(params);
        if (response.status === 201) {
          this.temporalAlert({
            show: true,
            message: "Convenio modificado con éxito",
            type: "success",
          });
        } else {
          this.temporalAlert({
            show: true,
            message: response.data.message,
            type: "error",
          });
        }
        this.setLoader(false);
      }
    },
    clean() {
      this.name = "";
      this.fecha_inicio = "";
      this.fecha_fin = "";
      this.justification = "";
      this.id_employee = "";
      this.image = null;
      this.file = null;
    },
    addData(property) {
      if (property === "name")
        this.loadGeneralData({ property: "nombre", data: this.name.trim() });
      if (property === "date_start") {
        let nueva_fecha_inicio = moment(this.fecha_inicio).format("YYYY-MM-DD HH:mm:ss");
        this.loadGeneralData({
          property: "fecha_inicio",
          data: nueva_fecha_inicio,
        });
      }
      if (property === "date_end") {
        let nueva_fecha_fin = moment(this.fecha_fin).format("YYYY-MM-DD HH:mm:ss");
        this.loadGeneralData({ property: "fecha_fin", data: nueva_fecha_fin });
      }
      if (property === "justification")
        this.loadGeneralData({
          property: "justificacion",
          data: this.justification.trim(),
        });
      if (property === "tecnico") {
        this.loadGeneralData({
          property: "id_empleado",
          data: this.id_employee,
        });
        let nom = this.lista_empleados.find(
          (item) => item.id === this.id_employee
        );
        this.loadGeneralData({
          property: "employee",
          data: this.getEmployeeName(nom),
        });
      }
      if (property === "image")
        this.loadGeneralData({ property: "imagen", data: this.file });
    },
    async setData(item) {
      this.saveIdAgreementMarco(item.id);
      this.name = item.nombre;
      this.justification = item.justificacion;
      this.id_employee = item.id_empleado;
      this.image = await this.getImageAgreement(item.id);
    },
    getEmployeeName(item) {
      let nombreTecnico = item.primer_nombre + " ";
      if (
        item.segundo_nombre !== "" &&
        item.segundo_nombre !== null &&
        item.segundo_nombre !== undefined
      )
        nombreTecnico += item.segundo_nombre + " ";
      if (
        item.tercer_nombre !== "" &&
        item.tercer_nombre !== null &&
        item.tercer_nombre !== undefined
      )
        nombreTecnico += item.tercer_nombre + " ";
      nombreTecnico += item.primer_apellido + " ";
      if (
        item.segundo_apellido !== "" &&
        item.segundo_apellido !== null &&
        item.segundo_apellido !== undefined
      )
        nombreTecnico += item.segundo_apellido + " ";
      if (
        item.tercer_apellido !== "" &&
        item.tercer_apellido !== null &&
        item.tercer_apellido !== undefined
      )
        nombreTecnico += item.tercer_apellido + " ";
      return nombreTecnico;
    },
  },
  async created() {
    this.clearGeneralData([]);
    await this.getEmpleados({ pagination: false, id_perfil: 16 });
    if (this.id_agreement_marco !== null) {
      await this.getAgreementMarco(this.id_agreement_marco);
      if (this.agreement !== null) {
        this.setData(this.agreement);
        this.editMode = true;
        if (this.agreement.fecha_inicio && this.agreement.fecha_fin) {
        this.setVigenciaState(false);
      }
      }
    }
  },
};
</script>
