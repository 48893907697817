<template>
  <v-card height="450">
    <v-row class="justify-start ma-2">
      <v-btn @click="$emit('close')" icon> <v-icon>mdi-close</v-icon></v-btn>
    </v-row>
    <v-card-title>
      <p class="text-h6 secondary--text">
        Seleccion de administrador de contrato
      </p>
    </v-card-title>
    <v-card-text>
      <v-row class="align-center">
        <v-text-field
          outlined
          label="DUI/NIT"
          v-model="documento"
          hide-details
          class="mx-2"
        ></v-text-field>
        <v-btn @click="isAdminist = true" color="success">verificar</v-btn>
      </v-row>
    </v-card-text>
    <v-card-text v-if="isAdminist">
      <v-row class="flex-column ml-5 mt-5">
        <p class="text-caption mb-0">Nombre</p>
        <p class="text-subtitle-2">Juan Jose Lopez Lopez</p>
      </v-row>
      <v-row class="flex-column ml-5">
        <p class="text-caption mb-0">DUI</p>
        <p class="text-subtitle-2">04342312-8</p>
      </v-row>
      <v-row class="flex-column ml-5">
        <p class="text-caption mb-0">NIT</p>
        <p class="text-subtitle-2">0614-091295-142-9</p>
      </v-row>
    </v-card-text>
    <v-card-actions class="d-flex flex-end">
      <v-row>
        <v-btn
          @click="$emit('close')"
          color="secondary"
          outlined
          small
          class="mx-3"
          >Cancelar</v-btn
        >
        <v-btn v-if="isAdminist" color="secondary" class="white--text" small
          >Seleccionar</v-btn
        >
      </v-row>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: "VerificarAdministradorComponent",
  data: () => ({
    documento: null,
    isAdminist: false,
  }),
};
</script>
