<template>
  <div>
    <stats-component :info="itemsFondos"></stats-component>
    <v-row class="justify-start">
      <v-btn
        color="secondary"
        :to="{ path: '/agregar_insumo' }"
        class="mt-5 white--text"
        small
        >Agregar insumo</v-btn
      >
    </v-row>
    <v-row class="justify-start">
      <p class="text-subtitle-1 Style">Lista de insumos</p>
      <v-data-table class="tableStyle" :headers="headers" :items="items">
        <template #[`item.acciones`]>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span> Editar </span>
          </v-tooltip>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span> Eliminar </span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-row>
    <v-row v-if="items.length > 0">
      <v-btn @click="redirecTo()" color="secondary" outlined small class="mx-3"
        >volver</v-btn
      >
      <v-btn color="secondary" class="white--text" small
        >Enviar a aprobacion</v-btn
      >
    </v-row>
  </div>
</template>
<script>
import statsComponent from "./StatsComponent.vue";
export default {
  name: "FondoPropioComponent",
  components: { statsComponent },
  data: () => ({
    itemsFondos: [
      {
        id: 1,
        value: "$10,000.00",
        title: "Fondo general disponible",
        color: "#309C6D",
      },
      {
        id: 2,
        value: "$2,500.00",
        title: "Fondo necesarios para solicitud",
        color: "#FFEAA7",
      },
      {
        id: 3,
        value: "$75,000.00",
        title: "Saldo de fondos propios",
        color: "#309C6D",
      },
    ],
    headers: [
      {
        text: "Correlativo",
        align: "center",
        value: "correlativo",
        width: "10%",
      },
      {
        text: "Mercancia",
        align: "center",
        value: "mercancia",
        width: "10%",
      },
      {
        text: "Unidad de medida",
        align: "center",
        value: "unidad_de_medida",
        width: "5%",
      },
      {
        text: "Mes",
        align: "center",
        value: "mes",
        width: "5%",
      },
      {
        text: "Cantidad",
        align: "center",
        value: "cantidad",
        width: "5%",
      },
      {
        text: "Precio unitario ($)",
        align: "center",
        value: "precio_unitario",
        width: "5%",
      },
      {
        text: "Total ($)",
        align: "center",
        value: "total",
        width: "10%",
      },
      {
        text: "Obj presupuestario",
        align: "center",
        value: "Obj_presupuestario",
        width: "10%",
      },
      {
        text: "Estado mercancias",
        align: "center",
        value: "estado_mercancias",
        width: "10%",
      },
      {
        text: "Contratacion",
        align: "center",
        value: "contratacion",
        width: "10%",
      },
      {
        text: "Mes inicio real",
        align: "center",
        value: "mes_inicio_real",
        width: "5%",
      },
      {
        text: "Usuario",
        align: "center",
        value: "usuario",
        width: "10%",
      },
      {
        text: "Acciones",
        align: "center",
        value: "acciones",
        width: "10%",
      },
    ],
    items: [
      {
        id: 1,
        correlativo: "G251615160651",
        mercancia: "Lapiz",
        unidad_de_medida: "N/A",
        mes: "Febrero",
        cantidad: 100,
        precio_unitario: "$0.05",
        total: "$5.00",
        Obj_presupuestario: "Lorem ipsum",
        estado_mercancias: "Nuevo",
        contratacion: "N/A",
        mes_inicio_real: "N/A",
        usuario: "Example example example example",
      },
    ],
  }),
  methods: {
    redirecTo() {
      this.$router.back();
    },
  },
};
</script>
<style>
.containerStyle {
  margin-top: 3rem;
}
.Style {
  margin-top: 3rem;
}

.tableStyle {
  width: 100%;
  margin-bottom: 3rem;
}
</style>