<template>
  <section fluid class="backgroundColor">
    <v-row cols="12">
      <p class="text-subtitle-1 mt-5">Listado de administradores</p>
      <v-data-table :headers="headers" class="dataTableStyle"> </v-data-table>
    </v-row>

    <v-dialog v-model="dialogAdministrador" max-width="600">
      <verificacion_administrador @close="dialogAdministrador = false" />
    </v-dialog>
  </section>
</template>
<script>
import verificacion_administrador from "./VerificarAdministradorComponent.vue";
export default {
  name: "AdministradorContratoComponent",
  components: {
    verificacion_administrador,
  },
  data: () => ({
    dialogAdministrador: false,
    headers: [
      {
        text: "Correlativo",
        align: "center",
        value: "correlativo",
        width: "10%",
      },
      {
        text: "Nombre",
        align: "center",
        value: "nombre",
        width: "20%",
      },
      {
        text: "Apellido",
        align: "center",
        value: "apellido",
        width: "20%",
      },
      {
        text: "DUI",
        align: "center",
        value: "dui",
        width: "20%",
      },
    ],
    items: [],
  }),
};
</script>
<style scoped>
.backgroundColor {
  background-color: #f4f7fd;
}
.dataTableStyle {
  width: 100%;
}
.btnStyle {
  margin-top: 3rem;
}
</style>
