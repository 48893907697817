<template>
  <v-row>
    <v-col cols="12">
      <stats-component :info="itemsFondos"></stats-component>
    </v-col>
    <!-- <v-col cols="12">
      <v-btn
        color="secondary"
        :to="{ path: `/agregar_insumo/${Number(this.$route.params.tipo)}` }"
        class="mt-5 white--text"
        small 
        >Agregar insumo</v-btn
      >
    </v-col> -->
    <v-col cols="12">
      <p class="text-subtitle-1 Style">Lista de insumos</p>
      <v-expansion-panels focusable accordion flat>
        <v-expansion-panel v-for="(item, i) in 5" :key="i" class="panel">
          <v-expansion-panel-header class="panel">
            <span>Item <v-icon color="green">mdi-check-circle-outline</v-icon></span></v-expansion-panel-header
          >
          <v-expansion-panel-content class="pa-5">
            <mercancias-insumos></mercancias-insumos>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <v-col cols="12">
      <v-btn @click="redirecTo()" color="secondary" outlined small class="mx-3"
        >volver</v-btn
      >
      <v-btn
        color="secondary"
        class="white--text"
        small
        @click="crearSolicitud()"
        >Crear solicitud</v-btn
      >
    </v-col>
  </v-row>
</template>
<script>
import MercanciasInsumos from "./MercanciaInsumoComponent.vue";
import statsComponent from "./StatsComponent.vue";

export default {
  name: "FondoGeneralComponent",
  components: { statsComponent, MercanciasInsumos },
  data: () => ({
    itemsFondos: [
      {
        id: 1,
        value: "$1,000.00",
        title: "Fondo general disponible",
        color: "#309C6D",
      },
      {
        id: 2,
        value: "$250.00",
        title: "Fondo necesarios para solicitud",
        color: "#FFEAA7",
      },
      {
        id: 3,
        value: "$750.00",
        title: "Saldo de fondos general",
        color: "#309C6D",
      },
    ],
    headers: [
      {
        text: "Correlativo",
        align: "center",
        value: "correlativo",
        width: "10%",
      },
      {
        text: "Mercancia",
        align: "center",
        value: "mercancia",
        width: "10%",
      },
      {
        text: "Unidad de medida",
        align: "center",
        value: "unidad_de_medida",
        width: "5%",
      },
      {
        text: "Mes",
        align: "center",
        value: "mes",
        width: "5%",
      },
      {
        text: "Cantidad",
        align: "center",
        value: "cantidad",
        width: "5%",
      },
      {
        text: "Precio unitario ($)",
        align: "center",
        value: "precio_unitario",
        width: "5%",
      },
      {
        text: "Total ($)",
        align: "center",
        value: "total",
        width: "10%",
      },
      {
        text: "Obj presupuestario",
        align: "center",
        value: "Obj_presupuestario",
        width: "10%",
      },
      {
        text: "Estado mercancias",
        align: "center",
        value: "estado_mercancias",
        width: "10%",
      },
      {
        text: "Contratacion",
        align: "center",
        value: "contratacion",
        width: "10%",
      },
      {
        text: "Mes inicio real",
        align: "center",
        value: "mes_inicio_real",
        width: "5%",
      },
      {
        text: "Usuario",
        align: "center",
        value: "usuario",
        width: "10%",
      },
      {
        text: "Acciones",
        align: "center",
        value: "acciones",
        width: "10%",
      },
    ],
    items: [
      {
        id: 1,
        correlativo: "G251615160651",
        mercancia: "Lapiz",
        unidad_de_medida: "N/A",
        mes: "Febrero",
        cantidad: 100,
        precio_unitario: "$0.05",
        total: "$5.00",
        Obj_presupuestario: "Lorem ipsum",
        estado_mercancias: "Nuevo",
        contratacion: "N/A",
        mes_inicio_real: "N/A",
        usuario: "Example example example example",
      },
    ],
  }),
  methods: {
    redirecTo() {
      this.$router.back();
    },

    crearSolicitud() {
      this.$router.push({ name: "solicitudes_obras_bienes_servicios" });
    },
  },
};
</script>
<style>
.Style {
  margin-top: 3rem;
}
.tableStyle {
  width: 100%;
  margin-bottom: 3rem;
}
.panel:focus,
.v-expansion-panel-header--active {
  background-color: #111c4e !important;
  color: white;
}

.v-expansion-panel-content {
  background-color: #f4f7fd !important;
}
.v-expansion-panel {
  margin: 2px;
}
.panel{
  border: 1px solid #111c4e !important;
}
</style>