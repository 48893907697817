<template>
  <v-row>
    <v-col class="d-flex justify-space-between" cols="12">
      <v-row class="d-flex justify-space-between">
        <v-btn text><v-icon>mdi-content-save</v-icon>Guardar cambios</v-btn>
        <stats-component :info="itemsFondos"></stats-component>
      </v-row>
    </v-col>
    <v-col class="mt-5" cols="12">
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-0 bgMinsal"
        hide-default-footer
        no-data-text="No hay productos"
      >
        <template #[`item.cantidad_aprobar`]="{ item }">
          <v-col class="d-flex justify-center">
            <v-col cols="5">
              <v-text-field
                v-model="item.cantidad_aprobar"
                outlined
                reverse
                type="number"
                class="mb-n6"
              ></v-text-field>
            </v-col>
          </v-col>
        </template>
        <template #[`item.estado`]="{ item }">
          <v-icon v-if="item.estado !== 'Aprobado'" color="green"
            >mdi-check-circle-outline</v-icon
          >
          <h4 v-else>Sin aprobación</h4>
        </template>
        <template #[`item.aprobar`]="{ item }">
          <v-col class="d-flex justify-center">
            <modal-solicitud></modal-solicitud>
          </v-col>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import ModalSolicitud from './ModalSolicitudComponent.vue';
import statsComponent from "./StatsComponent.vue";
export default {
  name: "MercanciaInsumoComponent",
  components: { statsComponent, ModalSolicitud},
  data: () => ({
    aprobado: false,
    itemsFondos: [
      {
        id: 1,
        value: "$1,000.00",
        title: "Fondo general disponible",
        color: "#309C6D",
      },
      {
        id: 2,
        value: "$250.00",
        title: "Fondo necesarios para solicitud",
        color: "#FFEAA7",
      },
      {
        id: 3,
        value: "$750.00",
        title: "Saldo de fondos general",
        color: "#309C6D",
      },
    ],
    headers: [
      {
        text: "Correlativo",
        align: "center",
        value: "correlativo",
      },
      {
        text: "Mercancia",
        align: "center",
        value: "mercancia",
      },
      {
        text: "Unidad de medida",
        align: "center",
        value: "unidad_de_medida",
      },
      {
        text: "Mes",
        align: "center",
        value: "mes",
      },
      {
        text: "Cantidad",
        align: "center",
        value: "cantidad",
      },
      {
        text: "Precio unitario ($)",
        align: "center",
        value: "precio_unitario",
      },
      {
        text: "Total ($)",
        align: "center",
        value: "total",
      },
      {
        text: "Cantidad aprobar",
        align: "center",
        value: "cantidad_aprobar",
      },
      {
        text: "Total ($)",
        align: "center",
        value: "total_aprobar",
      },
      {
        text: "Estado",
        align: "center",
        value: "estado",
        width: "10%",
      },
      {
        text: "Aprobar",
        align: "center",
        value: "aprobar",
      },
    ],
    items: [
      {
        id: 1,
        correlativo: "G251615160651",
        mercancia: "Lapiz",
        unidad_de_medida: "N/A",
        mes: "Febrero",
        cantidad: 100,
        precio_unitario: "$0.05",
        total: "$5.00",
        cantidad_aprobar: 20,
        total_aprobar: "Lorem ipsum",
        estado: "Aprobado",
        aprobar: false,
      },
    ],
  }),
};
</script>