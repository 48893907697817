<template>
  <section>
    <v-alert v-if="alert_obs" type="error" dismissible>
      Debe agregar al menos un OBS
      <template v-slot:close="{ toggle }">
        <v-btn @click="closeAlert(toggle)" color="white" text>
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </template>
    </v-alert>
    <v-row>
      <v-col cols="12" md="6">
        <v-autocomplete
          v-model="obs_selected"
          :items="listadoCatalogoObsFilter"
          @keyup="isLoading = true"
          item-text="codigoNombre"
          :filter="filterObsSelect"
          item-value="id"
          outlined
          :loading="isLoading"
          label="OBS *"
          append-outer-icon="mdi-plus-box-outline"
          :disabled="agreement_state === 4"
          :error-messages="obsErrorMessage"
          @input="$v.obs_selected.$touch()"
          @blur="$v.obs_selected.$touch()"
          @update:search-input="getObsSelect"
          @click:append-outer="postObsAgreement()"
          @click:clear="obs_selected = null"
          clearable
          hide-no-data
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p class="mb-2">Listado de OBS</p>
        <v-data-table item-class="celdas-tabla"
          :headers="headers"
          :items="lista_obs_convenio"
          hide-default-footer
        >
          <template v-slot:header.nombre_obs="{ header }">
            <center>{{ header.text}}</center>
          </template>
          <template v-slot:item.nombre_obs="{ item }">
            <label>{{ item.nombre_obs }}</label>
          </template>
          <template v-slot:[`item.correlativo`]="{ item }">
            <div class="celda">{{ item.correlativo }}</div>
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  @click="deleteObs(item.id)"
                >
                  mdi-delete-outline
                </v-icon>
              </template>
              <span> Eliminar </span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <div>
      <action-button-component :step="2" />
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import ActionButtonComponent from "./ActionButtonComponent.vue";

export default {
  name: "CatalogoOBSComponent",
  components: {
    ActionButtonComponent,
  },
  data: () => ({
    obs_selected: "",
    obs_convenio_to_add: [],
    correlativo: 0,
    headers: [
      {
        text: "Correlativo",
        align: "center",
        sortable: false,
        value: "correlativo",
      },
      {
        text: "Código OBS",
        align: "center",
        sortable: false,
        value: "codigo_obs",
      },
      {
        text: "Mercancía",
        align: "start",
        sortable: false,
        value: "nombre_obs",
      },
      {
        text: "Acciones",
        align: "center",
        sortable: false,
        value: "acciones",
      },
    ],
    listado_obs_select_new: [],
    isLoading: false,
  }),
  validations: {
    obs_selected: {
      required,
    },
  },
  computed: {
    ...mapState("convenioMarcoAdmin", [
      "id_agreement_marco",
      "obs_list",
      "lista_obs_convenio",
      "agreement_state",
      "alert_obs",
    ]),
    listadoCatalogoObsFilter() {
      return this.listado_obs_select_new.filter((obs) => {
        return !this.lista_obs_convenio.some((item) => item.id_obs === obs.id);
      });
    },
    obsErrorMessage() {
      const errors = [];
      if (!this.$v.obs_selected.$dirty) return errors;
      !this.$v.obs_selected.required &&
        errors.push("Debe seleccionar una opción");
      return errors;
    },
  },
  methods: {
    ...mapActions("convenioMarcoAdmin", [
      "loadGeneralData",
      "getObsList",
      "getObsAgreementList",
      "postAgreementObs",
      "deleteAgreementObs",
    ]),
    ...mapMutations("convenioMarcoAdmin", [
      "setObsAgreementList",
      "setBadDocumentos",
      "setAlertCatalogoObs",
    ]),
    filterObsSelect(item, queryText, itemText) {
      const words = queryText.split(" ");
      const hasWords = itemText.split(" ");

      return words.some((word) => {
        return hasWords.some((hasWord) => {
          return hasWord.toLowerCase().indexOf(word.toLowerCase()) > -1;
        });
      });
    },
    clean() {
      this.obs_selected = "";
      this.$v.obs_selected.$reset();
    },
    closeAlert(toggle) {
      this.setAlertCatalogoObs(false);
      toggle();
    },
    async postObsAgreement() {
      if (this.id_agreement_marco !== null) {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          const params = {
            id: this.id_agreement_marco,
            data: {
              id_obs: this.obs_selected,
            },
          };
          const response = await this.postAgreementObs(params);
          this.setBadDocumentos(false);
          if (response.status === 201) {
            this.temporalAlert({
              show: true,
              message: response.data.message,
              type: "success",
            });
            this.clean();
            await this.getObsAgreementList(this.id_agreement_marco);
          }
        }
      } else {
        this.temporalAlert({
          show: true,
          message:
            "Debe seleccionar un convenio o crear uno nuevo para agregar OBS",
          type: "error",
        });
      }
    },
    async deleteObs(id) {
      const response = await this.deleteAgreementObs(id);
      if (response.status === 204) {
        this.temporalAlert({
          show: true,
          message: "OBS borrado",
          type: "success",
        });
        await this.getObsAgreementList(this.id_agreement_marco);
      }
    },
    async getObsSelect(e) {
      if (this.obs_timer) {
        clearTimeout(this.obs_timer);
      }
      if (this.obs_selected > 0) {
        return;
      }

      this.obs_timer = setTimeout(async () => {
        let params = {
          search: e,
          inactivos: false,
        };

        let codigoObs = Number(e);

        if (
          (codigoObs && codigoObs != NaN && e?.length > 3) ||
          (e?.length > 2 && isNaN(codigoObs))
        ) {
          const { data } = await this.services.Obs.getObsList(params);
          const elements = [];
          data.forEach((obs) => {
            elements.push({
              id: obs.id,
              codigo: obs.codigo,
              nombre: obs.nombre,
              codigoNombre: `${obs.codigo} - ${obs.nombre}`,
            });
          });
          this.listado_obs_select_new = elements;
        }
        this.isLoading = false;
      }, 500);
    },
  },
  async created() {
    if (this.id_agreement_marco !== null) {
      await this.getObsAgreementList(this.id_agreement_marco);
    }
  },
};
</script>